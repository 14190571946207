import { ref, onMounted, onUnmounted, computed } from 'vue'

export function useScreen (breakpointSize = 0) {
  const MOBILE_VIEW = 900

  const width = ref(window.innerWidth)
  const breakpoint = ref(breakpointSize)

  const onResize = () => {
    width.value = window.innerWidth
  }

  onMounted(() => {
    window.addEventListener('resize', onResize)
  })

  onUnmounted(() => {
    window.removeEventListener('resize', onResize)
  })

  const isMobileView = computed(() => width.value < MOBILE_VIEW)
  const isDesktopView = computed(() => width.value >= MOBILE_VIEW)
  const isBreakpointView = computed(() => width.value < breakpoint.value)

  return {
    isMobileView,
    isDesktopView,
    screenWidth: width,
    isBreakpointView,
    breakpoint
  }
}
